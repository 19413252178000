// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { useInjectStyle } from "hooks";
// Components
import ReactMarkdown from "react-markdown";
import overrides from "./styles/overrides.css";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const MarkdownViewer = ({ children }) => {
  // Styles
  useInjectStyle("markdown-viewer", overrides);
  // Render
  return (
    <div className="markdown-viewer">
      <ReactMarkdown source={children} linkTarget="_blank" />
    </div>
  );
};

MarkdownViewer.propTypes = {
  children: PropTypes.string,
};

export default MarkdownViewer;
