// React
import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
// Helpers
import { isFunction } from "@mefisto/utils";
// Framework
import { usePortal } from "stack";
import { Tooltip } from "ui";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TimeAgo = ({
  timestamp,
  tooltipFormat = "LLL (z)",
  timeout = 15000,
  children,
}) => {
  // Framework
  const { time } = usePortal();
  // Callback
  const date = useMemo(() => {
    return time.fromFormat(timestamp);
  }, [time, timestamp]);
  const timeAgo = useMemo(() => {
    return time.timeAgo(date.valueOf());
  }, [time, date]);
  const formattedTimestamp = useMemo(() => {
    return date.format(tooltipFormat);
  }, [date, tooltipFormat]);
  // State
  const [value, setValue] = useState(timeAgo);
  // Effects
  useEffect(() => {
    const handleInterval = () => setValue(timeAgo);
    const interval = setInterval(handleInterval, timeout);
    handleInterval();
    return () => clearInterval(interval);
  }, [timeout, timeAgo]);
  // Render
  return (
    <Tooltip title={formattedTimestamp}>
      <div>{isFunction(children) ? children(value) : value}</div>
    </Tooltip>
  );
};

TimeAgo.propTypes = {
  timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  tooltipFormat: PropTypes.string,
  timeout: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
};

export default TimeAgo;
