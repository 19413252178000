// React
import React from "react";
import PropTypes from "prop-types";
// Framework
import { Spinner, Box, Typography, Cloak } from "ui/components";
import { Section } from "ui/section";
import { useTheme } from "theme";

////////////////////////////////////////////////////
/// Component
////////////////////////////////////////////////////

const TileInfoArea = ({
  context = "infoArea",
  value,
  title,
  disableMargin,
  disablePadding,
  width,
  height,
  minHeight,
  loading,
  titleProps,
  children,
}) => {
  // Styles
  const { radius, palette } = useTheme();
  // Render
  return (
    <Section context={context} value={value}>
      <Box
        position="relative"
        bgcolor="grey.100"
        width={width ?? "100%"}
        height={height}
        minHeight={minHeight}
        borderRadius={radius.normal}
        borderColor={palette.divider}
        border={1}
        mt={disableMargin ? 0 : 3}
        pt={disablePadding ? 0 : 2}
        px={disablePadding ? 0 : 3}
        pb={disablePadding ? 0 : 3}
      >
        {loading && <Spinner size="small" position="center" />}
        <Cloak enabled={loading}>
          {title && (
            <Box mb={2}>
              <Typography variant="subtitle2" {...titleProps}>
                {title}
              </Typography>
            </Box>
          )}
          {children}
        </Cloak>
      </Box>
    </Section>
  );
};

TileInfoArea.propTypes = {
  context: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  disableMargin: PropTypes.bool,
  disablePadding: PropTypes.bool,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  titleProps: PropTypes.any,
  children: PropTypes.any,
};

export default TileInfoArea;
